import $ from 'jquery/dist/jquery.slim';
import EtherLoto from '../../../build/contracts/EtherLoto.json';
import Web3, {
  providers
} from 'web3';
import contractAddress from './../contract_address.json';
import Web3EventCompatibility from 'web3-event-compatibility';

var address, expiration;
var selection = [];
var betSize = 6;
var betValue = 2e16;
var injectedWeb3 = false;

if (typeof web3 !== 'undefined') {
  web3 = new Web3(web3.currentProvider);

  // Only using Web3EventCompatibility for injected web3
  var web3Events = new Web3EventCompatibility(web3, window.web3);
  web3 = web3Events.web3

  var injectedWeb3 = true;
  console.log("injected web3")
} else {
  web3 = new Web3(new providers.HttpProvider("http://localhost:9545"));
  console.log("read only")
}

$('#contractAddress').text(contractAddress.address.substring(2));

var etherLotoContract;
web3.eth.getAccounts().then(accounts => {
  address = accounts[0];
});

etherLotoContract = new web3.eth.Contract(EtherLoto.abi, contractAddress.address);

etherLotoContract.events.BetReceived((error, result) => {
  if (!error) {
    setSpanPot(result.args._prizeAvailable.toNumber());
    convertResult(result.args._result);
  }
});

etherLotoContract.methods.taxes().call().then((prizeAvailable) => {
  prizeAvailable = parseInt(prizeAvailable) / 1e18 || 0;
  $('#spanPot').text(prizeAvailable);
});

etherLotoContract.methods.expiration().call().then(_expiration => {
  expiration = new Date(_expiration * 1000);
  var date_str = expiration.getDate().toString().padStart(2, '0')+
    '/'+(expiration.getMonth() + 1).toString().padStart(2, '0')+
    '/'+expiration.getFullYear().toString().padStart(2, '0')+
    ' '+expiration.getHours().toString().padStart(2, '0')+
    ':'+expiration.getMinutes().toString().padStart(2, '0');
  $('#spanBetsEnd').text(date_str);

  if (expiration > new Date()) {
    $('#isActive').html('not final');
  } else {
    $('#isActive').html('final');
    enableWithdraw();
  }
});

etherLotoContract.methods.betSize().call().then(_betSize => {
  betSize = _betSize;

  var selection = $('.selection');
  for (var i = 0; i < betSize; i++) {
    selection.append('<b class="ball">?</b>');
  }
});

etherLotoContract.methods.getResult().call().then(result => {
  convertResult(result);
});

etherLotoContract.methods.betValue().call().then(_betValue => {
  betValue = _betValue;
});

etherLotoContract.methods.prizeAvailable().call().then(prizeAvailable => {
  setSpanPot(prizeAvailable);
});

etherLotoContract.methods.maxNumber().call().then(maxNumber => {
  maxNumber = parseInt(maxNumber) + 1;

  populateBoard(maxNumber);

  setEvents();
});



// --------
// Dom
// --------
var convertResult = result => {
  var bet = $('.bet');
  bet.text('');
  for (var i = 0; i <= betSize; i++) {
    var start = i * 2;
    var int = result.substring(start, start + 2);
    if (i > 1 && int == 0) {
      break
    } else if (int == '0x') {
      continue
    } else {
      bet.append(`<b class="ball">${parseInt(int, 16)}</b>`);
    }
  }
}

var populateBoard = maxNumber => {
  var square = Math.ceil(Math.sqrt(maxNumber));
  var number = 0;
  var numbers = $('.board');
  for (var i = 0; i < square; i++) {
    numbers.append(`<div class="row row${i}"></div>`);
    for (var j = 0; j < square; j++) {
      if (number < maxNumber) {
        $(`.row.row${i}`).append(`<div class="cell" data-number="${number}" data-selected="false">${number}</div>`);
      } else {
        $(`.row.row${i}`).append(`<div class="cell">&nbsp;</div>`);
      }

      number++;
    }
  }
};

var setBalls = _ => {
  var balls = $('b.ball', '.selection');
  for (var i = 0; i < balls.length; i++) {
    $(balls[i]).text(selection[i] === undefined ? '?' : selection[i]);
  }
};

var setSpanPot = prizeAvailable => {
  prizeAvailable = parseInt(prizeAvailable) / 1e18 || 0;
  $('#spanPot').text('Ξ ' + prizeAvailable);
};

var setButtonState = _ => {
  if (expiration > new Date() && selection.length == betSize) {
    $('#buttonBet').prop('disabled', false);
  } else {
    $('#buttonBet').prop('disabled', true);
  }
};

var sortNumber = (a, b) => {
  return a - b;
};

var enableWithdraw = _ => {
  $('.card', '#withdraw-panel').attr('title', '');
  $('#text-secret', '#withdraw-panel').prop('disabled', '');
  $('#button-withdraw', '#withdraw-panel').prop('disabled', '');

};

var setEvents = _ => {
  $('.nav-link').on('click', e => {
    var active = $('.nav-link.active').get(0).hash;
    if (e.target.hash === active) {
      return;
    } else if (e.target.hash === '#Bet') {
      $('#withdraw-panel').hide();
      $('#bet-panel').show();
    } else {
      $('#bet-panel').hide();
      $('#withdraw-panel').show();
    }

    $('.nav-link').toggleClass('active');
  });

  $('.cell').on('click', e => {
    e = $(e.target);
    if (e.data('selected') === undefined) return;

    if (e.data('selected')) {
      for (var i = 0; i < selection.length; i++) {
        e.removeClass('selected');
        if (selection[i] === e.data('number')) {
          if (i < selection.length) {
            selection = selection.slice(0, i).concat(selection.slice(i + 1));
          } else {
            selection.pop();
          }
        }
      }
    } else if (selection.length < betSize) {
      e.addClass('selected');
      selection.push(e.data('number'));
      selection.sort(sortNumber);
    } else {
      alert('You\'ve selected all of your numbers.');
      return;
    }

    setButtonState();
    e.data('selected', !e.data('selected'));

    setBalls();
  });

  $('#buttonBet').on('click', _ => {
    if (!injectedWeb3) {
      alert('This is a read only version of the page;\r\nConsider installing Metamask!');
      return;
    }

    var secret = $('#textSecret', '#bet-panel').val();
    var bet = "0x";

    for (var i = 0; i < 25; i++) {
      if (i < betSize) {
        bet += selection[i].toString(16).padStart(2, '0');
      } else {
        bet += "00";
      }
    }

    etherLotoContract.methods.bet(bet, web3.utils.soliditySha3(secret)).send({
      from: address,
      value: betValue,
      gas: 150000
    }).on('confirmation', (confirmationNumber, receipt) => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      $('.cell').each((i, j) => {
        j = $(j);
        if (j.data('selected') !== undefined) {
          j.data('select', false);
          j.removeClass('selected');
        }
      })

      selection = [];
      setButtonState();
      setBalls();
    }).on('error', console.error);
  });

  $('#button-withdraw').on('click', _ => {
    if (!injectedWeb3) {
      alert('This is a read only version of the page;\r\nConsider installing Metamask!');
      return;
    }

    var secret = $('#text-secret', '#withdraw-panel').val();
    secret = web3.utils.fromAscii(secret);
    secret = web3.eth.abi.encodeParameter('bytes32', secret)

    etherLotoContract.methods.withdraw(secret).send({
      from: address,
      gas: 21000
    }).then(a => {
      console.log(a);
    });
  })
};